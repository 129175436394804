import React from "react"
import listStyles from './enroll-races-list.module.css'
import MyNavLink from './my-nav-link'
import PropTypes from "prop-types"


export default class EnrollRacesList extends React.Component {
  state = {
    redirect:false
  }
  componentDidMount() {
    let width=document.body.clientWidth
    console.log(width)
    this.setState({width})
  }

  static propTypes={
    color:PropTypes.string.isRequired,
    src:PropTypes.string.isRequired
  }
  render() {
    const color=this.props.color
    const src=this.props.src
    const enrollsinfo=[{},{}]
    const enrolllistPhone=enrollsinfo.map((enroll,index)=>
      <div className={listStyles.tableContentPhone} key={index}>
        <div className={listStyles.contentItemPhone}>
          <div className={listStyles.itemTitle}>报名项目</div>
          <div>太湖马拉松-全马</div>
        </div>
        <div className={listStyles.contentItemPhone}>
          <div className={listStyles.itemTitle}>报名人</div>
          <div>张迈</div>
        </div>
        <div className={listStyles.contentItemPhone}>
          <div className={listStyles.itemTitle}>报名状态</div>
          <div>等待审核</div>
        </div>
        <div className={listStyles.contentItemPhone}>
          <div className={listStyles.itemTitle}>报名时间</div>
          <div>2019-11-05 10:50</div>
        </div>
        <div  onClick={this.routePush}>
          <div className={listStyles.viewDetails} style={{color:'black',background:color}}>
            <div className={listStyles.viewDetailsText} >查看详情</div>
          </div>
        </div>
      </div>)
    const enrolllist=enrollsinfo.map((enroll,index)=>
      <div className={listStyles.tableContent} key={index}>
        <div style={{flex:2}}>
          太湖马拉松-全马
        </div>
        <div style={{flex:1}}>张迈</div>
        <div style={{flex:1}}>等待审核</div>
        <div style={{flex:1.5}}>2019-11-05 10:50</div>
        <MyNavLink style={{flex:1}} to={src}>
          <div className={listStyles.viewDetails} style={{color:'black',background:color}}>
            <div className={listStyles.viewDetailsText} >查看详情</div>
          </div>
        </MyNavLink>
      </div>)
    return(
        <div className={listStyles.profileBody}>
          <div className={listStyles.profileContainer}>
            <div className={listStyles.listContainer}>
              <div className={listStyles.listTitle}>我的报名</div>
              <div className={listStyles.tableItem}>
                <div style={{flex:2}}>报名项目</div>
                <div style={{flex:1}}>报名人</div>
                <div style={{flex:1}}>报名状态</div>
                <div style={{flex:1.5}}>报名时间</div>
                <div style={{flex:1}}></div>
              </div>
              <div style={{width:'100%'}}>{this.state.width>=580?enrolllist:enrolllistPhone}</div>
              {/*<div className="pagination">*/}
              {/*  <Pagination defaultCurrent={1} total={10} pageSize={5}/>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
    )
  }
}