import LayoutA4 from "../../components/layout-a4"
import React from "react"
import "../themeA1/enroll-info-list.css"
import { Pagination } from 'antd'
import MyNavLink from '../../components/my-nav-link'
import EnrollRacesList from '../../components/enroll-races-list'


export default class A4EnrollInfoList extends React.Component {
state = {
    redirect:false
  }



  render() {
   const color=this.props.pageContext.element.color
   const src="/demos/themeA4/profile/enrollInfo"

    return(
      <LayoutA4 >
        <EnrollRacesList color={color} src={src} />
      </LayoutA4>
    )
  }
}